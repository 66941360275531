import { initializeFX } from './scriptthree.js';

var nav, ham, close, home, events, ff, wheel, myspace, logout;
var init = false;
window.addEventListener('load', () => {
    nav = document.querySelector('nav');
    ham = document.querySelector('.MMFOOD');
    close = document.querySelector('.mobile-close');
    home = document.querySelector('nav .home');
    events = document.querySelector('nav .events');
    ff = document.querySelector('nav .funfive');
    wheel = document.querySelector('nav .wheel');
    myspace = document.querySelector('nav .myspace');
    logout = document.querySelector('nav .logout');
    ham.addEventListener('click', mobileMenu);
    close.addEventListener('click', mobileMenu);
});

const mobileMenu = () => {
    nav.classList.toggle('mobile-menu');
    if(nav.classList.contains('mobile-menu')){
        if(!init){
            init = true;
            initFX();
        }
        document.getElementById('webgl').style.display = 'block';
        const t = gsap.timeline();
        t.fromTo(home, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.1);
        t.fromTo(events, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.125);
        t.fromTo(ff, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.18);
        t.fromTo(wheel, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.21);
        t.fromTo(myspace, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.24);
        t.fromTo(logout, {opacity: 0}, {opacity: 1, duration: 0.5}, 0.3);
        t.fromTo(home, {translateY: '75px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.1);
        t.fromTo(events, {translateY: '95px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.125);
        t.fromTo(ff, {translateY: '105px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.18);
        t.fromTo(wheel, {translateY: '115px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.21);
        t.fromTo(myspace, {translateY: '125px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.24);
        t.fromTo(logout, {translateY: '155px'}, {translateY: '0px', duration: 0.75, ease: "elastic.out(1, 0.5)"}, 0.3);
        t.fromTo(home, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.1);
        t.fromTo(events, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.125);
        t.fromTo(ff, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.18);
        t.fromTo(wheel, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.21);
        t.fromTo(myspace, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.24);
        t.fromTo(logout, {scale: 0.25}, {scale: 1, duration: 0.85, ease: "elastic.out(1.5, 0.75)"}, 0.3);
    }
    else {
        document.getElementById('webgl').style.display = 'none';
    }
};

const initFX=()=>{
    initializeFX(document.getElementById('webgl'),null,null,false,'nav');
  }